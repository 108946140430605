import React from "react";

import { Layout } from "../../components/layout";
import { SEO } from "../../components/meta";
import { ContentObject } from "../../components/content";

import Heading from "./merge-notice-heading";
import Content from "./merge-notice-content";

const MergeNotice = () => (
  <>
    <SEO
      title={ContentObject.MergeNotice.meta.title}
      description={ContentObject.MergeNotice.meta.description}
    />

    <Layout header={{ position: "absolute" }} footer={{ size: "compact" }}>
      <section data-csweb="merge-notice">
        <Heading />
        <Content />
      </section>
    </Layout>
  </>
);

export default MergeNotice;
