import React from "react";

import { Grid, Cell } from "../../components/ui";

import { useStyletron } from "baseui";
import { ParagraphMedium, HeadingMedium } from "baseui/typography";

import { StyledLink } from "baseui/link";

const Items = () => {
  const [css, theme] = useStyletron();

  return (
    <article data-csweb="merge-notice-content">
      <Grid
        $style={{
          paddingTop: theme.sizing.scale2400,
          paddingBottom: theme.sizing.scale2400,
          [theme.breakpoints.mediaQueries.medium]: {
            paddingTop: theme.sizing.scale1200,
            paddingBottom: theme.sizing.scale1200,
          },
          [theme.breakpoints.mediaQueries.small]: {
            paddingTop: theme.sizing.scale600,
            paddingBottom: theme.sizing.scale600,
          },
        }}
      >
        <Cell skip={[0, 0, 2]} span={[4, 8, 8]}>
          <ParagraphMedium>
            Planowane połączenie Atende S.A. z jej spółką zależną Codeshine sp.
            z. o.o., prowadzące do zintegrowania zarówno obydwu spółek,
            jak&nbsp;i&nbsp;prowadzonej przez nie działalności, ma na celu
            ułatwienie wykorzystywania kompetencji zespołu tworzącego Codeshine
            sp. z o.o. bezpośrednio w strukturach organizacyjnych
            i&nbsp;projektach prowadzonych przez Atende S.A., a także
            zoptymalizowanie efektywności i&nbsp;usprawnienie wspólnych działań
            w obszarze tworzenia oprogramowania.
          </ParagraphMedium>

          <ParagraphMedium>
            Połączenie doprowadzi również do uproszczenia struktury
            organizacyjnej Grupy Kapitałowej Atende, a przez to obniżenia
            kosztów, jakie są związane z prowadzeniem działalności przez dwie
            odrębne spółki. Rozwój potencjału i oferty w obszarze tworzenia
            oprogramowania jest jednym z celów określonych w strategii rozwoju
            Atende.
          </ParagraphMedium>

          <HeadingMedium>Dokumentacja połączeniowa</HeadingMedium>

          <ParagraphMedium>
            <StyledLink
              $style={{
                color: `${theme.colors.contentAccent} !important`,
              }}
              href="https://atende.pl/pl/raporty-okresowe"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sprawozdania Zarządu z działalności i sprawozdania finansowe
              Atende S.A., wraz ze sprawozdaniami biegłego rewidenta z badań
              sprawozdań finansowych, za lata obrotowe 2020-2022.
            </StyledLink>
          </ParagraphMedium>

          <ul
            className={css({
              listStyleType: "square",
              color: `${theme.colors.contentPrimary} !important`,
            })}
          >
            <li>
              <ParagraphMedium>
                <StyledLink
                  $style={{
                    color: `${theme.colors.contentAccent} !important`,
                  }}
                  href="https://atende-website.cdn.prismic.io/atende-website/32686163-3f30-45fc-a729-62f798df6f55_Plan+po%C5%82%C4%85czenia_Atende_Codeshine-sig.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Plan połączenia Atende S.A. z Codeshine sp. z o.o. wraz z
                  załącznikami
                </StyledLink>
              </ParagraphMedium>
            </li>

            <li>
              <ParagraphMedium>
                <StyledLink
                  $style={{
                    color: `${theme.colors.contentAccent} !important`,
                  }}
                  href="https://atende-website.cdn.prismic.io/atende-website/79300cb7-5192-447d-bb49-5469acfe0723_Sprawozdanie+finansowe+Codeshine+2022.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sprawozdanie finansowe Codeshine za 2022 rok
                </StyledLink>
              </ParagraphMedium>
            </li>

            <li>
              <ParagraphMedium>
                <StyledLink
                  $style={{
                    color: `${theme.colors.contentAccent} !important`,
                  }}
                  href="https://atende-website.cdn.prismic.io/atende-website/b7a41360-0751-4e61-aad5-f56105690a75_Sprawozdanie+finansowe+Codeshine+2021.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sprawozdanie finansowe Codeshine za 2021 rok
                </StyledLink>
              </ParagraphMedium>
            </li>

            <li>
              <ParagraphMedium>
                <StyledLink
                  $style={{
                    color: `${theme.colors.contentAccent} !important`,
                  }}
                  href="https://atende-website.cdn.prismic.io/atende-website/26d3f5ce-fb98-4379-b44f-a3629313f265_Sprawozdanie+finansowe+Codeshine+2020.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sprawozdanie finansowe Codeshine za 2020 rok
                </StyledLink>
              </ParagraphMedium>
            </li>

            <li>
              <ParagraphMedium>
                <StyledLink
                  $style={{
                    color: `${theme.colors.contentAccent} !important`,
                  }}
                  href="https://atende-website.cdn.prismic.io/atende-website/a02ddfdd-b2c9-4745-985e-ad5798c11c25_Sprawozdanie+Zarza%CC%A8du+Codeshine+za+2022.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sprawozdanie zarządu z działalności Codeshine w 2022 roku
                </StyledLink>
              </ParagraphMedium>
            </li>

            <li>
              <ParagraphMedium>
                <StyledLink
                  $style={{
                    color: `${theme.colors.contentAccent} !important`,
                  }}
                  href="https://atende-website.cdn.prismic.io/atende-website/cba675df-07c7-4af7-9fd4-c807fb527483_Sprawozdanie+Zarza%CC%A8du+Codeshine+za+2021_signed.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sprawozdanie zarządu z działalności Codeshine w 2021 roku
                </StyledLink>
              </ParagraphMedium>
            </li>

            <li>
              <ParagraphMedium>
                <StyledLink
                  $style={{
                    color: `${theme.colors.contentAccent} !important`,
                  }}
                  href="https://atende-website.cdn.prismic.io/atende-website/cdfa42aa-c246-4744-a64d-b8e7e9f3bf4b_Sprawozdanie+Zarza%CC%A8du+Codeshine+za+2020.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sprawozdanie zarządu z działalności Codeshine w 2020 roku
                </StyledLink>
              </ParagraphMedium>
            </li>
          </ul>
        </Cell>
      </Grid>
    </article>
  );
};

export default Items;
